import React from 'react'
import {Helmet} from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

export const Seo = ({description, keywords, title, image, url, author}) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaAuthor = author || data.site.siteMetadata.author
        const metaUrl = url || data.site.siteMetadata.url
        const metaImage = image || data.site.siteMetadata.image
        const metaKeywords = keywords || ['modelowanie sylwetki', 'studio urody']
        return (
          <Helmet
            title={title}
            link={[
              {
                rel: 'canonical',
                href: metaUrl,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: metaTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'og:site_name',
                content: 'Modelowanie Sylwetki',
              },
              {
                property: 'og:url',
                content: metaUrl,
              },
              {
                property: 'og:locale',
                content: 'pl_PL',
              },
              {
                name: 'robots',
                content: 'index, follow',
              },
              {
                name: 'theme-color',
                content: '#fff',
              },
              {
                name: 'icon',
                href: 'src/images/logo-modelowanie-sylwetki-large.png',
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: 'keywords',
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
          >
            <html lang='pl-PL' />
          </Helmet>
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
        url
      }
    }
  }
`
