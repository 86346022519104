import React from 'react'
import logoLarge from '../images/logo-modelowanie-sylwetki-large.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {findIconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'
import regulamin from '../documents/klauzulainformacyjna.pdf'
import cennik from '../documents/cennik.pdf'

const Footer = () => {
  return (
    <>
      <div className='footer-container'>
        <div className='footer'>
          <div id='map' className='home-info-location'></div>
          <img src={logoLarge} width={300} height={258} alt='footer logo'></img>
          <div className='footer-info'>
            <div className='footer-text'>
              <h2>ADRES</h2>
              <p>
                ul. Trakt Lubelski 300B, <br />
                04-667 Warszawa
              </p>
            </div>

            <div className='footer-text'>
              <h2>KONTAKT </h2>
              <p>
                +48 728 008 928 <br />
                studio@modelowaniesylwetki-wawer.pl <br />
              </p>
            </div>

            <div className='footer-text'>
              <h2>GODZINY OTWARCIA</h2>
              <p>
                PON - PT 9:00-21:00 <br />
                SOB 9:00 - 15:00
              </p>
            </div>
          </div>
          <div className='social-media'>
            <a
              href='https://www.facebook.com/Modelowanie-Sylwetki-Wawer-101096555690380/?ref=page_internal'
              target='_blank'
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href='https://www.instagram.com/modelowanie_sylwetki_wawer/'
              target='_blank'
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <div className='regulamin'>
            <a href={regulamin} target='_blank'>
              Klauzula Informacyjna
            </a>
          </div>
          <div className='regulamin'>
            <a href={cennik} target='_blank'>
              Cennik
            </a>
          </div>
        </div>
      </div>
      <div className='copyright-notice'>
        <span>Copyright 2022</span>
      </div>
    </>
  )
}

export default Footer
