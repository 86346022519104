import React from 'react'
import {Link} from 'gatsby'
import logoLarge from '../images/logo-modelowanie-sylwetki-large.png'
import DropdownSidebar from './DropdownSidebar'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCross, faCrosshairs, faXmark} from '@fortawesome/free-solid-svg-icons'

const Sidebar = ({toggleSidebar}) => {
  const closeDropdowns = () => {
    let elems = document.querySelectorAll('.active')
    ;[].forEach.call(elems, function (el) {
      el.classList.remove('active')
    })
  }

  return (
    <div id='sidebar-container' className='sidebar-container'>
      <div className='topbar'>
        <FontAwesomeIcon
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          icon={faXmark}
        />
        <img src={logoLarge}></img>
      </div>

      <div className='sidebar'>
        <Link
          to='/'
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          className='sidebar-item'
        >
          Home
        </Link>

        <Link
          to='/o-nas'
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          className='sidebar-item'
        >
          O Nas
        </Link>

        <DropdownSidebar
          toggleSidebar={toggleSidebar}
          type={'zabiegi-na-twarz'}
          title={'Zabiegi na Twarz'}
          list={[
            'HIFU',
            'Endermomasaż',
            'Fala RF',
            'Oczyszczanie Wodorowe',
            'Sonoforeza',
            'Epilacja Laserowa',
          ]}
        />

        <DropdownSidebar
          toggleSidebar={toggleSidebar}
          type={'zabiegi-na-cialo'}
          title={'Zabiegi na Ciało'}
          list={[
            'Endermomasaż',
            'Liposukcja Kawitacyjna',
            'Lipolaser',
            'Kriolipoliza',
            'HIFU',
            'Fala RF',
            'Vacuum Masaż',
            'Oczyszczanie Wodorowe',
            'Elektrostymulacja',
            'Limfodrenaż',
            'Epilacja Laserowa',
          ]}
        />

        <DropdownSidebar
          type={'strefa-fitness'}
          title={'Strefa Fitness'}
          list={[
            'Vacu Bieżnia IR Professional',
            'Rollmasażer z IR',
            'Swan Orbitrek IR',
            'Rower Poziomowy',
          ]}
        />

        <Link
          to='/epilacja-laserowa'
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          className='sidebar-item'
        >
          Epilacja Laserowa
        </Link>

        <Link
          to='/galeria'
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          className='sidebar-item'
        >
          Galeria
        </Link>

        <Link
          to='/kontakt'
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          className='sidebar-item'
        >
          Kontakt
        </Link>

        <Link
          to='/voucher'
          onClick={() => {
            toggleSidebar()
            closeDropdowns()
          }}
          className='sidebar-item'
        >
          Podaruj Voucher
        </Link>
      </div>
    </div>
  )
}

export default Sidebar
