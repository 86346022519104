import React from 'react'
import {Link} from 'gatsby'
import logoLarge from '../images/logo-modelowanie-sylwetki-large.png'
import Dropdown from './Dropdown'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faBars, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'

library.add(faFacebookF, faInstagram, faPhone, faEnvelope)

const Navbar = ({toggleSidebar}) => {
  return (
    <>
      <div id='navbar-container' className={`navbar-container`}>
        <div className='social-media navbar'>
          <div>
            <a
              className='hidden'
              href='https://www.facebook.com/Modelowanie-Sylwetki-Wawer-101096555690380/?ref=page_internal'
              target='_blank'
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>

            <a
              className='hidden'
              href='https://www.instagram.com/modelowanie_sylwetki_wawer/'
              target='_blank'
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>

            <a href='mailto:studio@modelowaniesylwetki-wawer.pl'>
              <FontAwesomeIcon icon={faEnvelope} />
              <p>studio@modelowaniesylwetki-wawer.pl</p>
            </a>

            <a href='tel:+48 728 008 928'>
              <FontAwesomeIcon icon={faPhone} />
              <p>+48 728 008 928</p>
            </a>
          </div>
        </div>

        <div className='navbar-inner-container'>
          <Link to='/voucher' className='voucher'>
            <button>Podaruj Voucher</button>
          </Link>

          <Link to='/' className='navbar-logo'>
            <img
              className='logo-large'
              src={logoLarge}
              alt='logo'
              width='186px'
              height='170px'
            ></img>
          </Link>

          <div className='navbar'>
            <Link to='/' className='navbar-item'>
              Home
            </Link>

            <Link to='/o-nas' className='navbar-item'>
              O Nas
            </Link>

            <Dropdown
              type={'zabiegi-na-twarz'}
              title={'Zabiegi na Twarz'}
              list={[
                'HIFU',
                'Endermomasaż',
                'Fala RF',
                'Oczyszczanie Wodorowe',
                'Sonoforeza',

                'Epilacja Laserowa',
              ]}
            />

            <Dropdown
              type={'zabiegi-na-cialo'}
              title={'Zabiegi na Ciało'}
              list={[
                'Endermomasaż',
                'Liposukcja Kawitacyjna',
                'Lipolaser',
                'Kriolipoliza',
                'HIFU',
                'Fala RF',
                'Vacuum Masaż',
                'Oczyszczanie Wodorowe',
                'Elektrostymulacja',
                'Limfodrenaż',
                'Epilacja Laserowa',
              ]}
            />

            <Dropdown
              type={'strefa-fitness'}
              title={'Strefa Fitness'}
              list={[
                'Vacu Bieżnia IR Professional',
                'Rollmasażer z IR',
                'Swan Orbitrek IR',
                'Rower Poziomowy',
              ]}
            />

            <Link to='/epilacja-laserowa' className='navbar-item'>
              Epilacja Laserowa
            </Link>
            {/* 
            <Link to='/strefa-fitness' className='navbar-item'>
              Strefa Fitness
            </Link> */}

            <Link to='/galeria' className='navbar-item'>
              Galeria
            </Link>

            <Link to='/kontakt' className='navbar-item'>
              Kontakt
            </Link>
          </div>
          <FontAwesomeIcon icon={faBars} onClick={toggleSidebar} />
        </div>
      </div>
    </>
  )
}

export default Navbar
