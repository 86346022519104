import React, {useState, useEffect} from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import './global.scss'
import {Seo} from './Seo'
import CookieConsent from 'react-cookie-consent'
import regulamin from '../documents/klauzulainformacyjna.pdf'


//stops the fonat awesome icons appearing too big on page load
import '@fortawesome/fontawesome-svg-core/styles.css'
import {config} from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

const Layout = ({children}) => {
  const [navScrolled, setNavScrolled] = useState('')
  const [showSidebar, setShowSidebar] = useState(false)

  const toggleSidebar = () => {
    if (showSidebar === false) {
      document.getElementById('sidebar-container').style.right = '0'
      document.getElementById('sidebar-container').style.opacity = '100%'
      // document.body.style.overflow = 'hidden'
      setShowSidebar(true)
    } else if (showSidebar === true) {
      document.getElementById('sidebar-container').style.right = '-200%'
      document.getElementById('sidebar-container').style.opacity = '0'
      // document.body.style.overflow = 'scroll'
      setShowSidebar(false)
    }
  }

  useEffect(() => {}, [])

  return (
    <div className='layout'>
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar toggleSidebar={toggleSidebar} />
      <CookieConsent
        disableStyles={true}
        location='bottom'
        buttonClasses="btn-custom-class"
        containerClasses="container-custom-class"
        contentClasses="content-custom-class"
        buttonText='Akceptuje'
        declineButtonText='Decline'
        cookieName='gatsby-gdpr-google-analytics'
      >
        Korzystając z naszej strony wyrażasz zgodę na stosowanie plików cookies. Więcej informacji znajdziesz w naszej <a href={regulamin} target='_blank'>Polityce Prywatności</a>
      </CookieConsent>
      {children}
      <Footer />
    </div>
  )
}

export default Layout
