import React, {useState} from 'react'
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'

const DropdownSidebar = ({title, list, type, toggleSidebar}) => {
  const [toggleDrop, setToggleDrop] = useState('')

  const toggleMenu = () => {
    if (toggleDrop === '') {
      setToggleDrop('active')
      return
    }
    setToggleDrop('')
  }

  const makeLink = (item) => {
    //removes all accents e.g. ąćęłń óśźż -> aceln-oszz
    let link = item
      .replace(/\u0142/g, 'l')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
    return link
  }

  return (
    <div className='dropdown sidebar' onClick={toggleMenu} data-dropdown>
      <div className={`sidebar-item ${toggleDrop}`} data-dropdown-button>
        <div>{title}</div>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
      <div className={`dropdown-menu ${toggleDrop}`}>
        {list.map((item) => (
          <Link to={`/${type}/${makeLink(item)}`} onClick={toggleSidebar}>
            {item}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default DropdownSidebar
