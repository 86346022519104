import React, {useRef, useState, useEffect} from 'react'
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'

const Dropdown = ({title, list, type}) => {
  const ref = useRef()
  const [toggleDrop, setToggleDrop] = useState('')

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (ref.current && !ref.current.contains(e.target)) {
        setToggleDrop('')
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [toggleDrop])

  const toggleDropdownOnClick = () => {
    if (toggleDrop === '') {
      setToggleDrop('active')
      return
    }

    setToggleDrop('')
  }

  const makeLink = (item) => {
    //removes all accents e.g. ąćęłń óśźż -> aceln-oszz
    let link = item
      .replace(/\u0142/g, 'l')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
    return link
  }

  return (
    <div className='dropdown' ref={ref} onClick={toggleDropdownOnClick} data-dropdown>
      <div to='/services' className={`navbar-item ${toggleDrop}`} data-dropdown-button>
        <div>{title}</div>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
      <div className={`dropdown-menu ${toggleDrop}`}>
        {list.map((item) => (
          <Link to={`/${type}/${makeLink(item)}`}>{item}</Link>
        ))}
      </div>
    </div>
  )
}

export default Dropdown
